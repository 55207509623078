<script lang="ts">
	import type { ProductForCustomer } from "../../../../core/schema/Product";
	import type { ProductAmountPerCategory } from "../../../../core/schema/ProductAmountPerCategory";
	import Link from "../Link.svelte";
	import ProductCard from "./ProductCard.svelte";
	import type { Category } from "../../../../core/schema/Category.js";
	import { getGlobalSettings } from "../../getGlobalSettings.js";
	import { getCategoryPath } from "../../../../core/schema/paths/getCategoryPath.js";
	import { getContext } from "svelte";
	import { requiredCategoriesContextKey } from "../../contexts/requiredCategoriesContextKey.js";
	import type { RequiredCategories } from "../../../../core/schema/namesOfRequiredCategories.js";

	export let products: ProductForCustomer[];
	export let maxProductsPerCategory: ProductAmountPerCategory;
	export let headline = "Nejoblíbenější";
	export let category: Category;

	const { TENANT_LOCALE } = getGlobalSettings();
	const requiredCategories = getContext<RequiredCategories>(requiredCategoriesContextKey);
	$: isChristmas = category.id === requiredCategories.christmas.id;
</script>

<div>
	<h2 class="decorative-headline">{headline}</h2>
	<div class="product-card-wrapper mt-6 lg:mt-2">
		{#each products as product, index (product.id)}
			<ProductCard {product} {index} {maxProductsPerCategory} />
		{/each}
	</div>
	<Link
		class="mx-auto mt-10 text-center sm:!px-14"
		href="/{getCategoryPath(TENANT_LOCALE, category)}"
		asButton
		variant="secondary"
	>
		<div>
			{#if isChristmas}
				Zobrazit další z kategorie
			{:else}
				Zobrazit další
			{/if}
			<span class:lowercase={!isChristmas}>{category.name[TENANT_LOCALE]}</span>
		</div>
	</Link>
</div>
